@import './fonts/fonts';

@font-face {
    font-family: 'inter-regular';
    src: url('./fonts/Inter-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'inter-medium';
    src: url('./fonts/Inter-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'inter-semi-bold';
    src: url('./fonts/Inter-SemiBold.otf') format('opentype');
}

body {
    overflow-x: hidden !important;
}

body div,
p,
a,
span,
label,
tr,
text,
td {
    font-family: 'inter-regular', 'sans-serif';
}


// $max: 30;
// $offset: 10;
$unit: 'px'; // Feel free to change the unit.

@mixin list-loop($className, $styleName, $offset: 5, $max: 50) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit} !important;
        }

        $i: $i + $offset;
    }
}

@mixin fweight-loop($className, $styleName, $offset: 100, $max: 600) {
    $i: 0;

    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i} !important;
        }

        $i: $i + $offset;
    }
}

@include list-loop('.p-t-', 'padding-top');
@include list-loop('.p-r-', 'padding-right');
@include list-loop('.p-b-', 'padding-bottom');
@include list-loop('.p-l-', 'padding-left');
@include list-loop('.p-', 'padding');
@include list-loop('.m-t-', 'margin-top');
@include list-loop('.m-r-', 'margin-right');
@include list-loop('.m-b-', 'margin-bottom');
@include list-loop('.m-l-', 'margin-left');
@include list-loop('.m-', 'margin');
@include list-loop('.fsize-', 'font-size', 1, 30);

// #bg
$primary-bg: #00688a;
$secondary-bg: #f1f4f6;
$white-bg: #fff;

// #color
$primary-color: #000;
$secondary-color: #00688a;
$tertiary-color: #a4d2e3;

$white: #fff;
$black: #000000;

$fsize-default: 16px;
$fsize-small: 16px;
$fsize-medium: 20px;
$fsize-large: 24px;
$fsize-title: 28px;

$font_regular: 'inter-regular';
$font_medium: 'inter-medium';
$font_bold: 'inter-semi-bold';
$font_regular_italic: 'inter-regular-italic';

.black {
    color: $black !important;
}

.white {
    color: $white !important;
}

.bg-white {
    background: $white-bg !important;
}

.text {
    color: $black;
    font-size: $fsize-default;
    font-weight: 500 !important;
    word-break: break-word;
    font-family: $font_regular !important;
}

.text-small {
    color: $black;
    font-size: $fsize-small !important;
    font-weight: 400 !important;
    word-break: break-word;
    font-family: $font_regular !important;
}

.text-medium {
    color: $black;
    font-size: $fsize-medium !important;
    font-weight: 500 !important;
    word-break: break-word;
    font-family: $font_regular !important;
}

.text-large {
    color: $black;
    font-size: $fsize-large !important;
    font-weight: 500 !important;
    word-break: break-word;
    font-family: $font_medium !important;
}

.text-title {
    color: $black;
    font-size: $fsize-title !important;
    font-weight: 600 !important;
    word-break: break-word;
    font-family: $font_bold !important;
}

.f600 {
    font-weight: 600 !important;
}

.bg-primary {
    background: $primary-bg !important;
}

.clr-secondary {
    color: $secondary-color !important;
}

.clr-white {
    color: $white !important;
}

.clr-grey {
    color: $secondary-bg !important;
}

.bg-grey {
    background: $secondary-bg !important;
}

.footer {
    a {
        text-decoration: none !important;

        &:hover {
            text-decoration: underline !important;
        }
    }

}

a {
    text-decoration: none !important;

    &:hover {
        text-decoration: underline !important;
    }
}

.btn-primary {
    background: #00688a;
    min-height: 42px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 18px;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.app-container {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.contact-container {

    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.margin-auto {
    margin: auto;
}

.solutions {

    .ant-collapse {
        background: $white-bg !important;
        border: none !important;
    }

    .ant-collapse-item {
        border: none !important;
        margin-bottom: 20px !important;
        background: $secondary-bg !important;
        // border-top-left-radius: 24px !important;
        // border-top-right-radius: 24px !important;
    }

    .ant-collapse-header {
        padding: 24px 0 !important;
        padding-right: 1.5rem !important;

    }

    .ant-collapse-arrow {
        transform: rotate(90deg) !important;

        svg {
            width: 1.2em;
            height: 1.2em;
        }
    }

    .ant-collapse-item-active {
        .ant-collapse-arrow {
            transform: rotate(0deg) !important;
        }
    }

    .ant-tabs-tab {
        height: 62px;
        border: none !important;
        background: $white-bg !important;
        margin-right: 2px !important;

        &:hover,
        &:active {
            color: $black !important;
            background: $secondary-bg !important;
        }

        .text {
            // font-weight: 600 !important;
        }

        border-radius: 8px !important;
        margin-bottom: 20px !important;

        .tab-title {
            padding-right: 10rem !important;
        }
    }

    .ant-tabs-tabpane {
        border: 1px solid #fff;
        outline: 1px solid #fff;
    }

    .tab-title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 1.5rem !important;
    }

    .ant-tabs-tab-active {
        .text {
            color: $white !important;
        }

        &:hover {
            background: $primary-bg !important;
        }
    }

    .ant-tabs-tab-active,
    .ant-collapse-item-active {
        background: $primary-bg !important;

        .ant-collapse-header-text {
            .text {
                color: $white !important;
            }
        }

        .ant-collapse-expand-icon {
            color: $white !important;
        }
    }
}

.m-w-100 {
    max-width: 100%;
}

.fs-extra-large {
    font-size: 40px !important;
}

.app-logo {
    height: 50px;
}

@media only screen and (max-width: 568px) {

    .app-logo {
        height: 40px !important;
    }

    .fs-extra-large {
        font-size: 28px !important;
    }

    .text {
        font-size: 14px !important;
    }

    .text-medium {
        font-size: 16px !important;
    }

    .text-large {
        font-size: 20px !important;
    }

    .text-title {
        font-size: 24px !important;
    }

    .btn-primary {
        font-size: 14px !important
    }

    .ant-collapse-header-text {
        .text {
            color: $black !important;
            font-size: 16px !important;
        }
    }
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}